
// import { abtservice } from 'public/src/services/abt'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
// import { DETAIL_ABT, DETAIL_ABT_NEW } from './config.js'
let abtResult = undefined
// 获取服务端 ABT
// 这个方法不要用了 伙伴们
// 都用 fsAbt
export const getAbtFromSSR = (ssrAbtKey, key, isNew) => {
  let result = {}
  try {
    const abt = ssrAbtKey?.split?.('##') || []
    let target = abt.find(_ => !!~_.indexOf(key))
    if (target) {
      result = target?.split('_')?.[1] || ''
    }
    if (isNew && typeof result == 'string' && result) {
      return JSON.parse(result)
    }
  } catch (e) {
    console.error(e)
  }
  return result
}

/**
 * 获取保留款配置信息
 * @param {String} position // banner / bottom / button
 * @param {String} 腰带内容
 * @returns rightText 右侧显示文字
 */
export const getRetentionConfig = ({ position = '', isSoldOut, tsp = {}, contentText }) => {
  const _position = position == 'NO' ? '' : position
  if(isSoldOut || !tsp?.['3598']) return { show: false } // 把ab判断后移并抛出， 因为有些场景不绑定abt
  return { 
    // show: !!(_position && !isSoldOut && tsp?.['3598']),
    show: !!_position,
    position: _position,
    rightText: '',
    contentText
  }
}

// abt 统一请求
export const getDetailsClientAbt = () => {
  return new Promise(resolve => {
    if (abtResult) {
      resolve(abtResult)
      return
    }
    getUserAbtData().then(res => {
      abtResult = res
      resolve(abtResult)
    })
  })
}

export const validStoreCode = (store_code) => {
  const code = parseInt(+store_code)
  return code && code !== 1
}

export const isNotEmpty = (obj) => {
  return obj && JSON.stringify(obj) !== '{}'
}

export const catsIteration = (cates, catIds) => {
  for (let i = 0; i < cates.length; i++) {
    if (catIds.includes(cates[i].cat_id)) {
      return true
    }
    if (cates[i]?.children?.length) {
      const bool = catsIteration(cates[i].children, catIds)
      if (bool) return bool
    }
  }
  return false
}

// 全链路替代方案
export const getOpenTraceInfo = (posParams = {}) => {
  const keys = Object.keys(posParams)
  if (!keys.length) return ''
  if (!keys.every(key => !!posParams[key].poskey)) return ''
  let result = keys.reduce((acc, cur) => {
    let abtItem = posParams[cur]
    acc[cur] = {
      'abt-branchid': abtItem.child?.[0]?.bid,
      'abt-expid': abtItem.child?.[0]?.eid,
      'abt-poskey': abtItem.poskey,
      'abt-params': abtItem.p && typeof abtItem.p == 'string' ? abtItem.p : '',
      'abt-params-json': abtItem.p && typeof abtItem.p == 'object' ? abtItem.p : null
    }
    return acc
  }, {})
  return JSON.stringify(result)
}

/**
 * 数组去重
 * @param {*} target 目标数组
 * @param {*} filters 过滤数组
 */
export const deduplicationGoodsId = (target = [], filters = []) => {
  if (!target.length || !filters.length) return target
  const filtersItems = new Set(filters)
  return target.filter(item => !filtersItems.has(item.goodsId || item.goods_id))
}
